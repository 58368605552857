<template>
  <div class="bottom">
    <div class="col">
      <span>NewTown RolePlay - {{ new Date().getFullYear() }}</span>
      <span
        >Newtown RP не является аффилированным лицом и не одобрено Rockstar
        North, Take-Two Interactive или другим правообладателем. Все
        используемые товарные знаки принадлежат их соответствующим
        владельцам.</span
      >
    </div>
    <div class="contacts">
      <div class="row">
        <a href="/user-agreement.pdf" target="_blank" rel="noopener noreferrer"
          >Пользовательское соглашение</a
        >
        <a href="/security-policy.pdf" target="_blank" rel="noopener noreferrer"
          >Политика безопасности</a
        >
        <a href="/privacy-policy.pdf" target="_blank" rel="noopener noreferrer"
          >Политика конфиденциальности</a
        >

        <span class="name"
          >ИП Литовка А. Д.<br />ИНН 592111669730<br />ОГРНИП
          324595800040070</span
        >
      </div>
      <div class="row">
        <div class="cards">
          <div class="card"><img src="/cards/visa.png" alt="Visa" /></div>
          <div class="card"><img src="/cards/mir.png" alt="MIR" /></div>
          <div class="card"><img src="/cards/mastercard.png" alt="MasterCard" /></div>
          <div class="card"><img src="/cards/sbp.png" alt="СБП" /></div>
        </div>
      </div>

      <!-- <span>support@newtownrp.online</span>
      <a href="https://discord.gg/2dwANQtsWd"
        ><img src="@/assets/icons/discord.svg" alt="Discord"
      /></a> -->
    </div>
  </div>
</template>

<script>
import "./Bottom.scss";
export default {
  name: "BottomDiv",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    openPdf(event) {
      window.open(event.currentTarget.href, "_blank");
    },
  },
};
</script>
